import {css}            from 'lit-element';
import {registerStyles} from '@vaadin/vaadin-themable-mixin/register-styles.js';


registerStyles(
  'vaadin-grid',
  css`
    [part~='cell'].status {
      font-weight: bold;
    }
    [part~='cell'].green {
      background: green;
      color: #fff;
    }
    [part~='cell'].red {
      background: red;
      color: #fff;
    }
    [part~='cell'].grey {
      background: darkgrey;
    }
  `
);

registerStyles(
  'vaadin-app-layout',
  css`
    [part~='navbar'] {
      padding: 10px;
    }
  `
);
