import { customElement, html, LitElement } from 'lit-element';
import { LoadingElemStyles }               from './styles';
import '@material/mwc-circular-progress';


@customElement('loading-elem')
export class LoadingElem extends LitElement {

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LoadingElemStyles];
  }

  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <mwc-circular-progress indeterminate></mwc-circular-progress>
    `;
  }

}
