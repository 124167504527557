import { css } from 'lit-element';


export const LoadingElemStyles = css`
  :host {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    resize: both;
    overflow: auto;
    position: fixed;
    top:0;
    left:0;
    z-index: 99998;
    background: rgba(255, 255, 255, 0.7);
  }
`;

export default LoadingElemStyles;
